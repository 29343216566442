import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  logo: {
    display: 'flex',
    alignItems: 'center',
    height: '80px',
    padding: '7px 15px 0',
    justifyContent: 'space-between',
    '& > img': {
      height: '50px',
    },
  },
}))

export default useStyles
