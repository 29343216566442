import React from 'react'

//material
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'

//local
import { useStyles } from './ChannelSelector.styles'
import { IChannelSelector } from './ChannelSelector.interfaces'
import NoDataMessage from '../../../noDataMessage/NoDataMessage'

const ChannelSelector: React.FC<IChannelSelector> = ({
  selectedGuild,
  channels,
  selectedChannel,
  setSelectedChannel,
}) => {
  const classes = useStyles()

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedChannel(event.target.value)
  }
  return (
    <FormControl className={classes.root}>
      <InputLabel>Select existing channel</InputLabel>
      <Select
        value={selectedChannel}
        onChange={handleSelectChange}
        classes={{ select: classes.select }}
        IconComponent={ExpandMoreIcon}
        disabled={!selectedGuild}
      >
        {channels.length > 0 ? (
          channels.map(({ name }) => (
            <MenuItem value={name} key={name} className={classes.menuItem}>
              {name}
            </MenuItem>
          ))
        ) : (
          <NoDataMessage title="No channel data" />
        )}
      </Select>
    </FormControl>
  )
}

export default ChannelSelector
