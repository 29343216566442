import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  button: {
    '&.MuiButton-root': {
      backgroundColor: '#fff',
      width: '100%',
      height: '48px',
      borderRadius: '4px',
      border: '1px solid #0000001F',
      color: '#121E2B',
      justifyContent: 'center',
      fontWeight: 500,
      fontSize: '14px',
      boxShadow: 'none',
      textTransform: 'none',
      marginBottom: '15px',
      '&:hover, &.Mui-focused, &.Mui-selected': {
        background: 'none',
        border: '1px solid #0000001F',
        boxShadow: 'none',
      },
    },
  },
  icon: {
    width: '25px',
  },
}))

export default useStyles
