import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  text: {
    padding: '15px 0',
  },
  button: {
    '&.MuiButton-root': {
      margin: '15px 5px 0 0',
      width: '200px',
      height: '56px',
      boxShadow: 'none',
      textTransform: 'none',
      marginTop: '20px',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px',
  },
}))
