import React, { FC } from 'react'

//material
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CheckCircle from '@mui/icons-material/CheckCircle'

//local
import useStyles from './FinalStep.styles'
import { IComplete } from './FinalStep.interfaces'

const FinalStep: FC<IComplete> = ({ handleReset, title, buttonTitle }: IComplete) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.content}>
        <CheckCircle className={classes.checkIcon} />
        <Typography className={classes.title}>{title}</Typography>
      </div>
      <Button onClick={handleReset} className={classes.button} variant="contained" color="primary">
        {buttonTitle}
      </Button>
    </>
  )
}

export default FinalStep
