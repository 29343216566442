import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '25px',
  },
  title: {
    '& h2': {
      fontWeight: 'bold',
    },
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '20px',
  },
  actions: {
    marginBottom: '20px',
  },
  image: {
    width: '300px',
  },
  button: {
    '&.MuiButton-root': {
      width: '100%',
      height: '50px',
      boxShadow: 'none',
      textTransform: 'none',
    },
  },
}))
