/* eslint-disable */
import Moralis from 'moralis'
import { SUCCESS_MESSAGE } from './TransferTokenForm.alerts'
import { ITransfer, ITransferOptions } from './TransferTokenForm.interfaces'
import { getContract } from '../mintToken/uploadImage/UploadImage.services'

export const transfer: ITransfer = async (
  contractAddress,
  transferToAddress,
  chainId,
  tokenId,
  setLoading,
  showMessage,
) => {
  const contract = await getContract(contractAddress, chainId)
  const options: ITransferOptions = {
    type: contract.type.toLowerCase(),
    receiver: transferToAddress,
    contractAddress: contractAddress,
    tokenId: tokenId,
  }

  try {
    setLoading(true)
    const transaction = await Moralis.transfer(options)
    // @ts-ignore
    await transaction.wait()
    showMessage(SUCCESS_MESSAGE, 'success')
  } catch (e) {
    showMessage(e, 'error')
  } finally {
    setLoading(false)
  }
}
