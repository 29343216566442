import React, { FC } from 'react'
import MUITableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { ITableHead } from './TableHead.interfaces'
import useStyles from './TableHead.styles'

const TableHead: FC<ITableHead> = ({ row }: ITableHead) => {
  const classes = useStyles()

  return (
    <MUITableHead>
      <TableRow>
        {row.map(({ id, label }) => (
          <TableCell key={id} className={classes.cell}>
            {label}
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  )
}

export default TableHead
