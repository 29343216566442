import { API } from 'services/api'

export const getContractList = async (chainId: string, type: string) => {
  const params = new URLSearchParams({
    type: type,
  })

  try {
    if (chainId && type) {
      const response = await API.get(`/chains/${chainId}/contracts?${params}`)
      return await response.json()
    }
    return { items: [] }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e, 'error')
  }
}
