import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  field: {
    '&.MuiFormControl-root': {
      width: '385px',
      borderColor: primary.light,
      marginBottom: '30px',
    },
  },
  input: {
    color: primary.dark,
  },
}))

export default useStyles
