import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${primary.light}`,
    height: '100vh',
    width: '185px',
  },
  collapsed: {
    width: '80px',
    '& $itemTitle': {
      display: 'none',
    },
    '& $expandIcon': {
      '&.MuiSvgIcon-root': {
        transform: 'rotate(-90deg)',
        left: '89px',
      },
    },
  },
  expandIcon: {
    '&.MuiSvgIcon-root': {
      borderRadius: '50px',
      transform: 'rotate(90deg)',
      position: 'fixed',
      top: '35px',
      left: '165px',
      cursor: 'pointer',
      background: 'white',
      height: '30px',
      width: '30px',
    },
  },
  symbolItem: {
    '&.MuiListItem-root': {
      padding: '20px 25px',
      justifyContent: 'left',
    },
  },
  icon: {
    '&.MuiSvgIcon-root': {
      fontSize: '30px',
      color: primary.main,
      background: 'white',
      fontWeight: 700,
    },
  },
  itemTitle: {
    paddingLeft: '20px',
    display: 'block',
  },
}))

export default useStyles
