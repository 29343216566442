import React, { FC } from 'react'

//local
import { IStepperButtons } from './StepperButtons.interfaces'
import Button from '@mui/material/Button'
import useStyles from './StepperButtons.styles'

const StepperButtons: FC<IStepperButtons> = ({ activeStep, label, handleBack, handleNext }: IStepperButtons) => {
  const classes = useStyles()

  return (
    <div className={classes.buttons}>
      {activeStep >= 1 && (
        <Button onClick={handleBack} color="primary">
          Back
        </Button>
      )}
      <Button onClick={handleNext} variant="contained" color="primary">
        {label}
      </Button>
    </div>
  )
}

export default StepperButtons
