import React from 'react'
// import { Magic } from '../magic/Magic'
import useStyles from './Login.styles'

import Metamask from './../metamask/Metamask'

export const LogIn = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/* <Magic /> */}
      <Metamask />
    </div>
  )
}
