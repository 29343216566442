import React from 'react'

//material
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

//local
import { useStyles } from './GuildSelector.styles'
import { IGuildSelector } from './GuildSelector.interfaces'
import NoDataMessage from '../../../noDataMessage/NoDataMessage'

const GuildSelector: React.FC<IGuildSelector> = ({ guilds, selectedGuild, setSelectedGuild }) => {
  const classes = useStyles()

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedGuild(event.target.value)
  }
  return (
    <FormControl className={classes.root}>
      <InputLabel>Select guild</InputLabel>
      <Select
        value={selectedGuild}
        onChange={handleSelectChange}
        classes={{ select: classes.select }}
        IconComponent={ExpandMoreIcon}
      >
        {guilds.length > 0 ? (
          guilds.map(({ name, id }) => (
            <MenuItem value={id} key={id} className={classes.menuItem}>
              {name}
            </MenuItem>
          ))
        ) : (
          <NoDataMessage title="No guilds data" />
        )}
      </Select>
    </FormControl>
  )
}

export default GuildSelector
