import React, { useState } from 'react'

//material
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'

//local
import useStyles from './Tokens.styles'
import { tableHead } from './Tokens.config'
import Title from './../common/title/Title'
import TableHead from '../common/tableHead/TableHead'
import TableBodyContent from './tableBody/TableBody'
import { useParams } from 'react-router-dom'
import { ITokenTable } from './Tokens.interfaces'
import TablePagination from '../common/tablePagination/TablePagination'
import BackdropLoader from '../../backdrop/BackdropLoader'

const Tokens: React.FC<ITokenTable> = ({ tokens, chainId }) => {
  const classes = useStyles()
  const { contractId } = useParams()
  const [rowsPerPage, setRowsPerPage] = useState(4)
  const [page, setPage] = useState(0)
  const currentRows = tokens?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <Title title="Tokens" />
        <Table className={classes.table}>
          <TableHead row={tableHead} />
          <TableBody>
            {!!contractId && (
              <TableBodyContent
                currentRows={currentRows}
                contractId={contractId}
                setLoading={setLoading}
                chainId={chainId}
              />
            )}
          </TableBody>
        </Table>
        <TablePagination
          data={tokens}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
      <BackdropLoader open={loading} />
    </>
  )
}

export default Tokens
