import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import './App.css'
import Auth from './pages/auth/Auth'
import theme from './theme'
import useStyles from './App.styles'

const App: React.FunctionComponent = () => {
  const classes = useStyles()
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          classes={classes}
          preventDuplicate
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          hideIconVariant
        >
          <Auth />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
