import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    '&.MuiTypography-root': {
      padding: '20px 0 40px 0',
      fontSize: '25px',
      fontWeight: 'bold',
    },
  },
  form: {
    justifyContent: 'center',
    maxWidth: '800px',
    width: '100%',
  },
  label: {
    textAlign: 'left',
    color: 'grey',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  field: {
    width: '385px',
    borderColor: primary.light,
    marginBottom: '30px',
  },
  input: {
    color: primary.dark,
  },
}))

export default useStyles
