import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  title: {
    '&.MuiTypography-root': {
      fontSize: '25px',
      fontWeight: 700,
      textAlign: 'left',
      padding: '8px 0 25px 20px',
      color: primary.main,
    },
  },
}))

export default useStyles
