import React, { useEffect, useState } from 'react'
import { connectDiscord } from './Discord.auth.services'
import BackdropLoader from '../../components/backdrop/BackdropLoader'
import useSnackBar from '../../hooks/useSnackbar/useSnackbar'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { LOADER_MESSAGE } from './DiscordAuth.alerts'

const DiscordAuth: React.FunctionComponent = () => {
  const showMessage = useSnackBar()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const path = window.location.origin + location.pathname
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (code) void connectDiscord(code, path, setLoading, showMessage, navigate)
  }, [code, path, showMessage, navigate])

  return <BackdropLoader open={loading} message={LOADER_MESSAGE} />
}

export default DiscordAuth
