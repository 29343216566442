import Moralis from 'moralis'
import { components } from 'moralis/types/generated/web3Api'
import { getNetwork } from 'helpers/strings/strings'

export const getTransactions = async (chainId: string, publicKey: string) => {
  const options = {
    chain: getNetwork(chainId).toLowerCase() as components['schemas']['chainList'],
    address: publicKey,
    limit: 5,
  }
  return await Moralis.Web3API.account.getTransactions(options)
}
