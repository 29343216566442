import React from 'react'
import LogoutIcon from '@mui/icons-material/Logout'
import useStyles from './Logout.styles'
import useSidebarStyles from '../Sidebar.styles'
import { ILogout } from './Logout.interfaces'

const Logout: React.FC<ILogout> = ({ signOut }) => {
  const classes = useStyles()
  const sidebarClasses = useSidebarStyles()

  return (
    <div className={classes.logout}>
      <LogoutIcon onClick={signOut} className={classes.icon} />
      <div className={sidebarClasses.itemTitle}>Log out</div>
    </div>
  )
}

export default Logout
