import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { useStyles } from './ContractTypeSelect.styles'
import { getContractTypes } from './ContractTypeSelect.services'
import { ISelectProps } from 'components/shared/interfaces/select.interfaces'

export const ContractTypeSelect: React.FC<ISelectProps<string>> = ({ value, setValue }) => {
  const classes = useStyles()
  const [types, setTypes] = useState<string[]>([])

  const options = types.length
    ? types.map((type) => {
        return { value: type, label: type }
      })
    : []

  useEffect(() => {
    void (async () => {
      const result = await getContractTypes()
      if (result) {
        setTypes(result)
        setValue(result[1])
      }
    })()
  }, [setValue])

  const handleSelectChange = (event: SelectChangeEvent) => {
    const type = event.target.value as string
    if (!type) return
    setValue(type)
  }

  return (
    <FormControl className={classes.root}>
      <InputLabel>Filter contract by type</InputLabel>
      <Select
        value={value || ''}
        onChange={handleSelectChange}
        classes={{ select: classes.select }}
        disableUnderline
        variant="standard"
        IconComponent={ExpandMoreIcon}
      >
        {options.length &&
          options.map(({ label, value }) => (
            <MenuItem value={value} key={value} className={classes.menuItem}>
              {label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
