import React, { FC } from 'react'
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom'
import Button from '@mui/material/Button'
import { IButtonLink } from './ButtonLink.interfaces'

const ButtonLink: FC<IButtonLink> = ({ classes, label, to }) => {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function MyLink(linkProps, ref) {
        return <Link ref={ref} to={to} {...linkProps} />
      }),
    [to],
  )
  return (
    <Button className={classes.button} color="primary" component={CustomLink} size="small">
      {label}
    </Button>
  )
}

export default ButtonLink
