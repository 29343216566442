import React from 'react'
import { Typography } from '@mui/material'
import useStyles from './NoDataMessage.styles'
import { INoDataMessage } from './NoDataMessage.interfaces'

const NoDataMessage: React.FC<INoDataMessage> = ({ title }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography>{title}</Typography>
    </div>
  )
}

export default NoDataMessage
