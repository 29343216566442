import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '335px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
}))

export default useStyles
