import React from 'react'

//local
import useStyles from './CreateContract.styles'
import Title from '../common/title/Title'
import DeployContract from '../../components/deployContract/DeployContract'

const CreateContract: React.FunctionComponent = () => {
  const classes = useStyles()
  return (
    <>
      <Title title="Create contract" />
      <div className={classes.root}>
        <div className={classes.content}>
          <DeployContract />
        </div>
      </div>
    </>
  )
}

export default CreateContract
