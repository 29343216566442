import React from 'react'
import { useStyles } from './Header.styles'
import ChainIdSelector from '../chainIdSelector/ChainIdSelector'

const Header: React.FunctionComponent = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ChainIdSelector />
    </div>
  )
}

export default Header
