export const tableHead = [
  { id: 1, label: 'Id' },
  { id: 2, label: 'Image' },
  { id: 3, label: 'Name' },
  { id: 4, label: 'Description' },
  { id: 5, label: 'Links' },
  { id: 6, label: 'Actions' },
]

export const availableChainIds = ['1', '4', '137', '80001']
