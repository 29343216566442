import React, { FC } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useStyles } from './BackdropLoader.styles'
import { IBackdropLoader } from './BackdropLoader.interfaces'

const BackdropLoader: FC<IBackdropLoader> = ({ open, message }) => {
  const classes = useStyles()
  return (
    <Backdrop open={open} className={classes.root}>
      <CircularProgress />
      {message && <div className={classes.message}>{message}</div>}
    </Backdrop>
  )
}

export default BackdropLoader
