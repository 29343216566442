import React, { useState } from 'react'
import { Link } from 'react-router-dom'

//material
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import CollectionsBookmarkIconOutlined from '@mui/icons-material/CollectionsBookmarkOutlined'
import TableChartIconOutlined from '@mui/icons-material/TableChartOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'

//local
import useStyles from './Sidebar.styles'
import { ROUTE_DASHBOARD, ROUTE_DEPLOY_CONTRACT, ROUTE_USER_PROFILE } from '../../routes/RouteMap'
import classNames from 'classnames'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Logout from './logout/Logout'
import Logo from './logo/Logo'
import { ISidebar } from './Sidebar.interfaces'

const Sidebar: React.FC<ISidebar> = ({ signOut }) => {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(true)

  const handleToggleSidebar = () => setIsExpanded(!isExpanded)

  const toggleClass = () => classNames(classes.sidebar, { [classes.collapsed]: isExpanded })

  return (
    <div className={toggleClass()}>
      <Logo />
      <ExpandMoreIcon className={classes.expandIcon} onClick={handleToggleSidebar} />
      <List>
        <ListItem button component={Link} to={ROUTE_DASHBOARD} className={classes.symbolItem}>
          <TableChartIconOutlined className={classes.icon} />
          <div className={classes.itemTitle}>Dashboard</div>
        </ListItem>
        <ListItem button component={Link} to={ROUTE_DEPLOY_CONTRACT} className={classes.symbolItem}>
          <CollectionsBookmarkIconOutlined className={classes.icon} />
          <div className={classes.itemTitle}>Deploy</div>
        </ListItem>
        <ListItem button component={Link} to={ROUTE_USER_PROFILE} className={classes.symbolItem}>
          <PersonOutlineIcon className={classes.icon} />
          <div className={classes.itemTitle}>Profile</div>
        </ListItem>
      </List>
      <Logout signOut={signOut} />
    </div>
  )
}

export default Sidebar
