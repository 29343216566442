import React, { useContext, useEffect, useState } from 'react'
import { useMoralis } from 'react-moralis'

// material
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

//local
import { useStyles } from './Transactions.styles'
import { ITransactions } from './Transactions.interfaces'
import { getTransactions } from './Transactions.services'
import AppContext from 'contexts/appContext/AppContext'
import { ITransactionTable } from './Transactions.interfaces'
import TableHead from '../common/tableHead/TableHead'
import { tableHead } from './Transactions.config'
import { getFormattedDate } from 'helpers/strings/strings'
import Status from './status/Status'
import NoChainIdMessage from '../common/noChainIdMessage/NoChainIdMessage'
import Title from '../common/title/Title'

const Transactions: React.FC<ITransactionTable> = ({ setLoading }) => {
  const classes = useStyles()
  const { chainId } = useContext(AppContext)
  const { user } = useMoralis()
  const publicKey = user?.get('ethAddress')
  const [transactions, setTransactions] = useState<ITransactions[]>([])

  useEffect(() => {
    void (async () => {
      if (!chainId) return
      const data = await getTransactions(chainId, publicKey)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setTransactions(data.result)
    })()
    return () => setTransactions([])
  }, [chainId, setLoading, publicKey])

  return (
    <Paper className={classes.paper} elevation={0}>
      <Title title="Transactions" />
      <Table className={classes.table}>
        <TableHead row={tableHead} />
        <TableBody>
          {chainId ? (
            transactions.map(({ hash, block_timestamp, receipt_status }) => (
              <TableRow key={hash}>
                <TableCell>{hash}</TableCell>
                <TableCell>{getFormattedDate(block_timestamp)}</TableCell>
                <TableCell>
                  <Status receiptStatus={receipt_status} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoChainIdMessage />
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default Transactions
