import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  root: {
    '&.MuiBackdrop-root': {
      zIndex: 1400,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  message: {
    padding: '25px 0',
  },
}))
