import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles(({ palette: { secondary } }: Theme) => ({
  divider: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    width: '195px',
    paddingTop: '10px',
    color: secondary.contrastText,
    '&:before': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${secondary.contrastText}`,
      marginRight: '1em',
    },
    '&:after': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${secondary.contrastText}`,
      marginLeft: '1em',
    },
  },
}))
