import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    top: '15%',
  },
  content: {
    textAlign: 'center',
  },
}))

export default useStyles
