/* eslint-disable no-console */
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Moralis from 'moralis'
import { useMoralis } from 'react-moralis'
import Button from '@mui/material/Button'
import AppContext from 'contexts/appContext/AppContext'
import { TProvider } from 'contexts/appContext/AppContext.interfaces'
import useStyles from './Metamask.styles'
import MetamaskIcon from './metamask-fox.svg'
import { config } from '../../../env-config'
import useSnackBar from '../../../hooks/useSnackbar/useSnackbar'
import { getLoginParams } from 'helpers/moralis/moralis'
import { ROUTE_DASHBOARD } from 'routes/RouteMap'
import { getAccessToken } from '../Auth.services'

const Metamask: React.FunctionComponent = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { handleSetProvider } = useContext(AppContext)
  const { authenticate, setUserData, isAuthenticated } = useMoralis()
  const showMessage = useSnackBar()
  const { handleSetChainId } = useContext(AppContext)
  const providerType = 'metamask'

  const handleConnect = async () => {
    const availableChains = config.chainIds.map((chain) => chain.value)
    if (!window.ethereum || !availableChains.includes(window.ethereum?.networkVersion))
      return showMessage(
        `Please switch metamask chain to either ${config.chainIds.map((chain) => chain.label).join(', ')}. `,
        'error',
      )

    const accessToken = await getAccessToken(providerType)

    if (accessToken) {
      const user = await authenticate(getLoginParams())
      user?.set('provider', providerType)
      const provider = await Moralis.provider
      await user?.save()

      handleSetProvider(provider as TProvider)
      if (isAuthenticated) await setUserData({ provider: providerType })
      localStorage.setItem('chainId', window.ethereum?.networkVersion)
      handleSetChainId(window.ethereum?.networkVersion)
      navigate(ROUTE_DASHBOARD)
    }
  }

  return (
    <Button variant="contained" onClick={handleConnect} className={classes.button}>
      <img src={MetamaskIcon} alt="Metamask-icon" className={classes.icon} />
      &nbsp; Connect with Metamask
    </Button>
  )
}

export default Metamask
