import { API } from 'services/api'

export const getContractTypes = async () => {
  try {
    const response = await API.get('/contracts/types')
    return await response.json()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e, 'error')
  }
}
