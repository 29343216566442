export const scanLinkByChainId = [
  { value: '1', label: 'etherscan.io' },
  { value: '5', label: 'goerli.etherscan.io' },
  { value: '137', label: 'polygonscan.com' },
  { value: '80001', label: 'mumbai.polygonscan.com' },
]

export const openSeaLinkByChainId = [
  { value: '1', label: 'opensea.io/assets' },
  { value: '4', label: 'testnets.opensea.io/assets' },
  { value: '137', label: 'opensea.io/assets' },
  { value: '80001', label: 'testnets.opensea.io/assets/mumbai' },
]
