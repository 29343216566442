import React, { FC } from 'react'
import MUITablePagination from '@mui/material/TablePagination'
import { ITablePagination } from './TablePagination.interfaces'

const TablePagination: FC<ITablePagination> = ({
  data,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
}: ITablePagination) => {
  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value))
    setPage(0)
  }

  return (
    <MUITablePagination
      rowsPerPageOptions={[4, 8]}
      component="div"
      count={data?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export default TablePagination
