import { IFormData } from './formData.interfaces'

export const createFormData = (data: IFormData): FormData => {
  const formData = new FormData()
  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}
