import { IArtifactFile } from './ContractSelect.interfaces'

export const getContractNames = async () => {
  try {
    const data = await fetch(`https://unpkg.com/@credenza-web3/contracts/artifacts/?meta`)
    const t = await data.json()
    return t.files.map((item: IArtifactFile) => item.path.split('/artifacts/')[1])
  } catch (e) {
    throw new Error(e)
  }
}

export const getContractJson = async (name: string) => {
  try {
    const data = await fetch(`https://unpkg.com/@credenza-web3/contracts/artifacts/${name}`)
    return await data.json()
  } catch (e) {
    throw new Error(e)
  }
}
