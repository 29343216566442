import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMoralis } from 'react-moralis'

// local
import Sidebar from 'components/sidebar/Sidebar'
import Header from 'components/header/Header'
import AppRoutes from 'routes/AppRoutes'
import { ROUTE_SIGNIN } from 'routes/RouteMap'
import AppContext from 'contexts/appContext/AppContext'
import { TProvider } from 'contexts/appContext/AppContext.interfaces'
import useStyles from './Auth.styles'
import { LogIn } from './login/LogIn'

const Auth: React.FunctionComponent = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { user, logout } = useMoralis()

  const [chainId, setChainId] = useState<string>('')
  const [provider, setProvider] = useState<TProvider>({ isStatus: false })

  const handleSetChainId = (data: string) => setChainId(data)

  const handleSetProvider = (data: TProvider) => setProvider(data)

  const signOut = async () => {
    await logout()
    navigate(ROUTE_SIGNIN)
  }

  if (!user) return <LogIn />

  return (
    <AppContext.Provider
      value={{
        chainId,
        handleSetChainId,
        provider,
        handleSetProvider,
      }}
    >
      <div className={classes.app}>
        <Sidebar signOut={signOut} />
        <div className={classes.pages}>
          <Header />
          <AppRoutes />
        </div>
      </div>
    </AppContext.Provider>
  )
}

export default Auth
