import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  middleText: {
    padding: '0 15px',
  },
  button: {
    '&.MuiButton-root': {
      width: '168px',
      height: '50px',
      marginTop: '25px',
      boxShadow: 'none',
      textTransform: 'none',
    },
  },
}))
