export const formFields = {
  contractId: '',
  name: '',
  description: '',
  externalUrl: '',
  publicKey: '',
}

export const steps = [
  { id: 1, label: 'Minting' },
  { id: 2, label: 'Upload image' },
]
