import React, { useContext, useEffect, useState } from 'react'

// material
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'

// local
import useStyles from './Contracts.styles'
import TablePagination from '../common/tablePagination/TablePagination'
import { IContracts, IContract } from './Contracts.interfaces'
import { getContractList } from './Contracts.services'
import { tableHead } from './Contracts.config'
import Title from './../common/title/Title'
import TableHead from '../common/tableHead/TableHead'
import TableBodyContent from './tableBody/TableBody'
import AppContext from 'contexts/appContext/AppContext'
import { ContractTypeSelect } from '../../contractTypeSelect/ContractTypeSelect'

const Contracts: React.FC<IContract> = () => {
  const classes = useStyles()
  const { chainId } = useContext(AppContext)
  const [contracts, setContracts] = useState<IContracts[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(4)
  const [page, setPage] = useState(0)
  const currentRows = contracts?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const [type, setType] = useState<string>('')

  useEffect(() => {
    const getContracts = async () => {
      if (!chainId || !type) return

      const result = await getContractList(chainId, type)
      if (result.items) setContracts(result.items)
    }
    void getContracts()

    return () => setContracts([])
  }, [chainId, type])

  return (
    <>
      <ContractTypeSelect value={type} setValue={setType} />
      <Paper className={classes.paper} elevation={0}>
        <Title title="Contracts" />
        <Table className={classes.table}>
          <TableHead row={tableHead} />
          <TableBody>
            <TableBodyContent currentRows={currentRows} />
          </TableBody>
        </Table>
        <TablePagination
          data={contracts}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </>
  )
}

export default Contracts
