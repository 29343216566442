/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import CircularProgress from '@mui/material/CircularProgress'
import { ISelectProps } from 'components/shared/interfaces/select.interfaces'
import { getContractJson, getContractNames } from './ContractSelect.services'
import { IContract } from 'components/deployContract/DeployContract.interfaces'

export const ContractSelect: React.FC<ISelectProps<IContract | null>> = ({ setValue }) => {
  const [contracts, setContracts] = useState<string[]>([])
  const [selectValue, setSelectValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const options = contracts.length
    ? contracts.map((contract) => {
        return { value: contract, label: contract }
      })
    : []

  useEffect(() => {
    void (async () => {
      try {
        setLoading(true)
        const result = await getContractNames()
        if (result) {
          setValue(await getContractJson(result[1]))
          setContracts(result)
          setSelectValue(result[1])
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
      }
    })()
  }, [setValue])

  const handleSelectChange = (event: SelectChangeEvent) => {
    const contract = event.target.value as string
    if (!contract) return
    try {
      setLoading(true)
      void (async () => {
        setValue(await getContractJson(contract))
        setSelectValue(contract)
        setLoading(false)
      })()
    } catch (e) {
      setLoading(false)
    }
  }

  if (loading) return <CircularProgress />
  return (
    <FormControl>
      <InputLabel>Select contract by name</InputLabel>
      <Select
        value={selectValue || ''}
        onChange={handleSelectChange}
        disableUnderline
        variant="standard"
        IconComponent={ExpandMoreIcon}
      >
        {options.length &&
          options.map(({ label, value }) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
