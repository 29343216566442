import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px 20px',
  },
  network: {
    textTransform: 'uppercase',
    color: '#06D6A0',
    paddingLeft: '10px',
  },
  chainId: {
    paddingLeft: '10px',
  },
  button: {
    '&.MuiButton-root': {
      width: '120px',
      height: '45px',
      boxShadow: 'none',
      textTransform: 'none',
    },
  },
}))

export default useStyles
