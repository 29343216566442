import React, { FC } from 'react'
import { TextField } from '@mui/material'

//local
import useStyles from './ExternalUrl.styles'
import { IExternalUrlInput } from './ExternalUrl.interfaces'

const ExternalUrl: FC<IExternalUrlInput> = ({ value }: IExternalUrlInput) => {
  const classes = useStyles()

  return (
    <TextField
      disabled
      value={value}
      className={classes.field}
      color="primary"
      variant="outlined"
      InputProps={{ classes: { input: classes.input } }}
      placeholder="External Url"
    />
  )
}

export default ExternalUrl
