import { makeStyles } from '@mui/styles'
// import theme from '../../../../theme'

// TODO: add status color to theme
// const {  warning } = theme.palette

const useStyles = makeStyles(() => ({
  completed: {
    padding: '7px',
    borderRadius: '25px',
    width: '100px',
    backgroundColor: '#EBFFFA',
    color: '#06D6A0',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  inProgress: {
    padding: '7px',
    borderRadius: '25px',
    width: '100px',
    backgroundColor: '#FFF7E6',
    color: '#FFC53D',
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
  },
}))

export default useStyles
