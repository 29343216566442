import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  cell: {
    '&.MuiTableCell-root': {
      fontWeight: 'bold',
    },
  },
}))

export default useStyles
