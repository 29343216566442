import React, { FC } from 'react'

// local
import NoDataMessage from '../../common/noDataMessage/NoDataMessage'
import { ITableBodyContent } from './TableBody.interfaces'
import { TokenRow } from './tokenRow/TokenRow'

const TableBodyContent: FC<ITableBodyContent> = ({ currentRows, contractId, chainId }) => {
  if (currentRows.length) {
    return (
      <>
        {currentRows.map((token) => (
          <TokenRow key={contractId + token.token_id} token={token} chainId={chainId} contractId={contractId} />
        ))}
      </>
    )
  }

  return <NoDataMessage />
}

export default TableBodyContent
