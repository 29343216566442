/* eslint-disable */
import Moralis from 'moralis'
import { API } from 'services/api'
import { IMAGE_SUCCESS, SUCCESS_MESSAGE } from './UploadImage.alerts'
import { IUploadFileToIPFS, IUploadMetaToDB } from './UploadImage.interfaces'
import { resyncMetadata } from 'helpers/moralis/moralis'
import { getContractJson } from 'components/contractSelect/ContractSelect.services'

export const getContract = async (contractId: string, chainId: string) => {
  const response = await API.get(`/chains/${chainId}/contracts/${contractId}`)
  return await response.json()
}

export const mintToken: IUploadFileToIPFS = async (
  file,
  saveFile,
  chainId,
  data,
  contractId,
  showMessage,
  setLoading,
  publicKey,
) => {
  const filename = file.name.split('.')[0]
  setLoading(true)
  try {
    const dbContract = await getContract(contractId, chainId)
    const fileData = await getContractJson(dbContract.file.name)
    const ethers = Moralis.web3Library
    const provider = new ethers.providers.Web3Provider((await Moralis.provider) as any)
    const contract = new ethers.Contract(dbContract.address, fileData.abi, provider.getSigner())

    const mintMethod = contract.mint ?? contract.mintToken ?? contract.issueMembership
    if (!mintMethod) return showMessage('Contract has no mint functionality', 'error')

    const moralisImageFile = await saveFile(filename, file, { saveIPFS: true })
    if (!moralisImageFile) return showMessage('Token image has not been uploaded', 'error')
    const meta = {
      ...data,
      // @ts-ignore
      image: `ipfs://${moralisImageFile._hash}`,
    }

    const metaResponse = await uploadMetaToDB(meta, showMessage)
    const result = await mintMethod(publicKey, metaResponse.url)
    const nft = await result.wait()
    const tokenId = ethers.BigNumber.from(nft.events[0].args.tokenId._hex).toNumber()
    await resyncMetadata(chainId, dbContract.address, tokenId)
    showMessage(SUCCESS_MESSAGE, 'success')
  } catch (e) {
    showMessage(e.response.data.message || e.message, 'error')
  } finally {
    setLoading(false)
  }
}

const uploadMetaToDB: IUploadMetaToDB = async (meta, showMessage) => {
  try {
    const result = await API.post('/contracts/uploadNftMeta', meta)
    showMessage(IMAGE_SUCCESS, 'success')
    return result.json()
  } catch (e) {
    showMessage(e.response.data.message, 'error')
  }
}
