import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  button: {
    '&.MuiButton-root': {
      margin: '0 10px',
      padding: '5px 10px',
      boxShadow: 'none',
      textTransform: 'none',
      backgroundColor: primary.light,
    },
  },
}))

export default useStyles
