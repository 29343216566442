/* eslint-disable no-console */
/* eslint-disable max-lines-per-function */
import React, { useState, useContext } from 'react'
import Moralis from 'moralis'
import { Wallet } from '@ethersproject/wallet'
import { QRCodeSVG } from 'qrcode.react'

// material
import { Button, Typography } from '@mui/material'
import { IGeneratedKeys } from './GeneratorKeys.interfaces'

// local
import useStyles from './GeneratorKeys.styles'
import AppContext from 'contexts/appContext/AppContext'
import { ScanTypes } from './GeneratorKeys.enums'

const GeneratorKeys: React.FunctionComponent = () => {
  const ethers = Moralis.web3Library
  const { chainId } = useContext(AppContext)
  const classes = useStyles()
  const [qrCode, setQrCode] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [keys, setKeys] = useState<IGeneratedKeys>()

  const handleGenerateKeys = async () => {
    const wallet = ethers.Wallet.createRandom()
    await generateCode(wallet)
    const { privateKey, publicKey, address } = wallet
    setKeys({ privateKey, publicKey, address })
  }
  const generateCode = async (wallet: Wallet) => {
    setErrorMsg('')
    try {
      const timestamp = String(new Date().getTime())
      const signature = await wallet.signMessage(timestamp)
      const data = JSON.stringify({
        scanType: ScanTypes.PASSPORT_ID,
        payload: timestamp,
        chainId,
        sig: signature,
      })

      setQrCode(Buffer.from(data).toString('base64'))
    } catch (err) {
      setErrorMsg('QR code generation failed')
    }
  }
  return (
    <div className={classes.contrainer}>
      <Typography>Generate New Keys</Typography>
      <Button className={classes.generateButton} variant="contained" color="primary" onClick={handleGenerateKeys}>
        Generate
      </Button>

      {!!keys?.address && (
        <div className={classes.keysContrainer}>
          <Typography>
            Address - <code className={classes.key}>{keys.address}</code>
          </Typography>
          <Typography>
            PublicKey - <code className={classes.key}>{keys.publicKey}</code>
          </Typography>
          <Typography>
            PrivateKey - <code className={classes.key}>{keys.privateKey}</code>
          </Typography>
          {!!qrCode && <QRCodeSVG value={qrCode} size={200} />}
          {!!errorMsg && <Typography>{errorMsg}</Typography>}
        </div>
      )}
    </div>
  )
}

export default GeneratorKeys
