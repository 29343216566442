import React, { FC } from 'react'
import etherScan from './etherscan-logo.png'
import useStyles from './Etherscan.styles'

interface IEtherscan {
  link: string
}

const EtherScan: FC<IEtherscan> = ({ link }) => {
  const classes = useStyles()

  const handleRedirect = () => window.open(link, '_blank')

  return <img src={etherScan} alt="EtherScan-logo" className={classes.icon} onClick={handleRedirect} />
}

export default EtherScan
