import React from 'react'

import Title from '../common/title/Title'
import DiscordAccounts from '../../components/discord/accounts/DiscordAccounts'
import GeneratorKeys from 'components/keys/GeneratorKeys'

const UserProfile: React.FunctionComponent = () => {
  return (
    <div>
      <Title title="My profile" />
      <DiscordAccounts />

      <hr />

      <GeneratorKeys />
    </div>
  )
}

export default UserProfile
