import React, { useState } from 'react'

//local
import StepperLine from '../../components/mintToken/stepperLine/StepperLine'
import useStyles from './MintToken.styles'
import WizardContext from '../../contexts/wizard/WizardContext'
import StepContent from '../../components/mintToken/stepContent/StepContent'
import { formFields, steps } from './MintToken.config'
import { IFormFields } from './MintToken.interfaces'
import FinalStep from '../../components/mintToken/finalStep/FinalStep'
import Title from '../common/title/Title'

const MintToken: React.FunctionComponent = () => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [tokenForm, setTokenFrom] = useState<IFormFields>(formFields)

  const handleReset = () => setActiveStep(0)

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1)

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1)

  const handleSetTokenForm = (data: IFormFields) => setTokenFrom(data)

  return (
    <WizardContext.Provider value={{ handleBack, handleNext, activeStep, handleSetTokenForm, tokenForm }}>
      <Title title="Mint token" />
      <div className={classes.root}>
        <StepperLine activeStep={activeStep} steps={steps} />
        <div className={classes.content}>
          <div>
            {activeStep === steps.length ? (
              <FinalStep handleReset={handleReset} title="Done!" buttonTitle="Mint new token" />
            ) : (
              <StepContent />
            )}
          </div>
        </div>
      </div>
    </WizardContext.Provider>
  )
}

export default MintToken
