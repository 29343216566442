import React, { FC } from 'react'
import { TextField } from '@mui/material'

// local
import { useStyles } from './RecipientPublicKey.styles'
import { IRecipientPublicKey } from './RecipientPublicKey.interfaces'

const RecipientPublicKey: FC<IRecipientPublicKey> = ({ value, setState }) => {
  const classes = useStyles()

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value)
  }

  return (
    <div className={classes.root}>
      <TextField
        label="Recipient public key"
        variant="outlined"
        value={value}
        className={classes.input}
        onChange={handleFieldChange}
      />
    </div>
  )
}

export default RecipientPublicKey
