import React from 'react'
import logo from '../logo.png'
import useStyles from './Logo.styles'

const Logo: React.FunctionComponent = () => {
  const classes = useStyles()

  return (
    <div className={classes.logo}>
      <img src={logo} alt="Macrodemic-logo" />
    </div>
  )
}

export default Logo
