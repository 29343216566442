import React, { useContext, useEffect, useState } from 'react'

//material
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

//local
import { useStyles } from './ConnectChannel.styles'
import { IChannels, IConnectChannel, IGuilds } from './ConnectChannel.interfaces'
import { connectChannel, getDiscordGuilds, getGuildChannel } from './ConnectChannel.services'
import GuildSelector from './guildSelector/GuildSelector'
import ChannelSelector from './channelSelector/ChannelSelector'
import CreateChannel from './createChannel/CreateChannel'
import { createFormData } from '../../../helpers/formData/formData'
import useSnackBar from '../../../hooks/useSnackbar/useSnackbar'
import BackdropLoader from '../../backdrop/BackdropLoader'
import AppContext from '../../../contexts/appContext/AppContext'
import { CHAIN_ID_MESSAGE } from './ConnectChannel.alerts'

const ConnectChannel: React.FC<IConnectChannel> = ({ contractId }) => {
  const classes = useStyles()
  const showMessage = useSnackBar()
  const { chainId } = useContext(AppContext)

  const [guilds, setGuilds] = useState<IGuilds[]>([])
  const [selectedGuild, setSelectedGuild] = useState('')

  const [channels, setChannels] = useState<IChannels[]>([])
  const [selectedChannel, setSelectedChannel] = useState('')

  const [newChannel, setNewChannel] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    void (async () => {
      const guilds = await getDiscordGuilds()
      setGuilds(guilds)
    })()
  }, [])

  useEffect(() => {
    if (!selectedGuild) return
    void (async () => {
      const channels = await getGuildChannel(selectedGuild)
      setChannels(channels)
    })()
  }, [selectedGuild])

  const handleSave = () => {
    if (!chainId) showMessage(CHAIN_ID_MESSAGE, 'info')
    setLoading(true)
    const data = {
      contractAddress: contractId,
      name: `${newChannel || selectedChannel}`,
    }

    const formData = createFormData(data)
    connectChannel(formData, showMessage, selectedGuild, setLoading, contractId, chainId)
  }

  return (
    <>
      <GuildSelector guilds={guilds} selectedGuild={selectedGuild} setSelectedGuild={setSelectedGuild} />
      <div className={classes.row}>
        <ChannelSelector
          selectedGuild={selectedGuild}
          channels={channels}
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
        />
        <Typography className={classes.middleText}>or</Typography>
        <CreateChannel value={newChannel} setState={setNewChannel} selectedGuild={selectedGuild} />
      </div>
      <Button onClick={handleSave} color="primary" className={classes.button} variant="contained">
        Connect
      </Button>
      <BackdropLoader open={loading} />
    </>
  )
}

export default ConnectChannel
