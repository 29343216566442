import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMoralis } from 'react-moralis'

// material
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

// local
import useStyles from './Contract.styles'
import { getContract, getContractTokens } from './Contract.services'
import { IContract, ITokens } from './Contract.interfaces'
import OpenSea from 'components/tables/common/links/opensea/Opensea'
import EtherScan from 'components/tables/common/links/etherscan/Etherscan'
import Title from '../common/title/Title'
import Tokens from 'components/tables/tokens/Tokens'
import ContractHead from './head/ContractHead'
import { getOpenSeaLink, getScanLink } from 'helpers/strings/strings'
import BackdropLoader from 'components/backdrop/BackdropLoader'
import ConnectChannel from 'components/discord/connectChannel/ConnectChannel'
import AppContext from 'contexts/appContext/AppContext'

const Contract: React.FunctionComponent = () => {
  const classes = useStyles()
  const { contractId } = useParams()
  const { user } = useMoralis()
  const publicKey = user?.get('ethAddress')
  const [contract, setContract] = useState<IContract>()
  const [tokens, setTokens] = useState<ITokens>([])
  const [loading, setLoading] = useState(false)
  const { chainId } = useContext(AppContext)

  useEffect(() => {
    void (async () => {
      if (!chainId || !contractId) return
      setLoading(true)
      const result = await getContract(contractId as string, chainId as string, setLoading)
      setContract(result)
    })()
  }, [contractId, chainId, publicKey])

  useEffect(() => {
    void (async () => {
      if (contract) {
        const tokenList = await getContractTokens(publicKey, contract.chainId as string, contract.address as string)
        setTokens(tokenList.result as ITokens)
      }
    })()
  }, [publicKey, contract])

  return (
    <>
      <Title title="Contract" />
      {contract && <ContractHead contract={contract} contractId={contractId as string} />}
      <div className={classes.row}>
        <Paper elevation={0} className={classes.paper}>
          <List>
            <ListItem className={classes.item}>
              <Typography className={classes.itemTitle}>Name:</Typography>
              <Typography>{contract?.file?.name}</Typography>
            </ListItem>
            <ListItem className={classes.item}>
              <Typography className={classes.itemTitle}>Links:</Typography>
              {tokens.length > 0 && contract && (
                <OpenSea link={`https://${getOpenSeaLink(contract.chainId)}/${contractId}/${tokens[0].token_id}`} />
              )}
              {contract && <EtherScan link={`https://${getScanLink(contract.chainId)}/address/${contractId}`} />}
            </ListItem>
          </List>
        </Paper>
        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.boxTitle}>Connect Discord channel</Typography>
          <ConnectChannel contractId={contractId as string} />
        </Paper>
      </div>
      {contract && <Tokens tokens={tokens} chainId={contract.chainId} />}
      <BackdropLoader open={loading} />
    </>
  )
}

export default Contract
