import { ILoginParams } from './moralis.interfaces'
import { config } from '../../env-config'

export const getLoginParams: ILoginParams = (provider, chainId, email) => {
  const mapChainIdToMagicLogin = () => {
    switch (chainId) {
      case '56':
        return {
          rpcUrl: 'https://bsc-dataseed.binance.org/',
          chainId: 56,
        }
      case '97':
        return {
          rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
          chainId: 97,
        }
      case '137':
        return {
          rpcUrl: 'https://polygon-rpc.com',
          chainId: 137,
        }
      case '80001':
        return {
          rpcUrl: 'https://rpc-mumbai.matic.today',
          chainId: 80001,
        }
      case '5':
        return 'goerli'
      case '1':
        return 'mainnet'
      default:
        throw new Error('Unsupported chainId - ' + chainId)
    }
  }

  switch (provider) {
    case 'magicLink':
      return {
        provider: 'magicLink',
        email,
        apiKey: config.magic.apiKey,
        network: mapChainIdToMagicLogin(),
      }
    default:
      return {}
  }
}

export const resyncMetadata = async (chainId: string, contractAddress: string, tokenId: number | string) => {
  const chain = `0x${Number(chainId).toString(16)}`
  const url = `https://deep-index.moralis.io/api/v2/nft/${contractAddress}/${tokenId}/metadata/resync?chain=${chain}&flag=uri&mode=sync`
  const options = {
    accept: 'application/json',
    'X-API-Key': config.apiKey,
  }
  await fetch(url, { headers: options })
}
