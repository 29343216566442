import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    boxShadow: 'none',
    border: `2px solid ${primary.light}`,
    borderTop: 0,
    overflowX: 'hidden',
    overflow: 'auto',
  },
  table: {
    minWidth: '900px',
    padding: '10px',
    fontSize: '15px',
    fontWeight: 600,
    '& > tbody > tr > td': {
      padding: '14px',
    },
  },
}))
