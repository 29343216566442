import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  icon: {
    height: '25px',
    width: '25px',
    cursor: 'pointer',
  },
}))

export default useStyles
