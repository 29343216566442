// authentication
export const ROUTE_SIGNIN = '/sign-in'
export const ROUTE_DISCORD_AUTH = '/discord'

// pages
export const ROUTE_TOKENS = '/tokens'
export const ROUTE_DASHBOARD = '/dashboard'
export const ROUTE_DEPLOY_CONTRACT = '/deploy-contract'
export const ROUTE_MINT_TOKEN = '/mint-token/contract/:id'
export const ROUTE_CONTRACT = '/contract/:contractId'
export const ROUTE_USER_PROFILE = '/user-profile'
export const ROUTE_TOKEN_LIST = '/tokens/contract/:id'

// urls
export const URL_DISCORD = `https://discord.com/oauth2/authorize?client_id=895563918594961419&redirect_uri=${encodeURIComponent(
  window.location.origin + '/discord',
)}&scope=identify%20guilds.join&response_type=code`
export const URL_CLAIM_TOKEN_LINK = 'https://staging.nftplusplus.ml/claimToken'
