import React, { FC } from 'react'
import { TextField } from '@mui/material'

//local
import useStyles from './ContractId.styles'
import { IContractInput } from './ContractId.interfaces'

const ContractId: FC<IContractInput> = ({ value }: IContractInput) => {
  const classes = useStyles()

  return (
    <TextField
      disabled
      value={value}
      className={classes.field}
      color="primary"
      variant="outlined"
      InputProps={{ classes: { input: classes.input } }}
      placeholder="Contract id"
    />
  )
}

export default ContractId
