import React, { FC } from 'react'

//material
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

//local
import { IStepperLine } from './StepperLine.interfaces'

const StepperLine: FC<IStepperLine> = ({ activeStep, steps }: IStepperLine) => {
  return (
    <Stepper alternativeLabel activeStep={activeStep}>
      {steps.map(({ label, id }) => (
        <Step key={id}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default StepperLine
