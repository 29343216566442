import React, { useContext, useEffect, useState } from 'react'

// material
import TableCell from '@mui/material/TableCell'
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow'

// local
import { getOpenSeaLink, getScanLink } from 'helpers/strings/strings'
import { resyncMetadata } from 'helpers/moralis/moralis'
import AppContext from 'contexts/appContext/AppContext'
import { availableChainIds } from '../../Tokens.config'
import OpenSea from '../../../common/links/opensea/Opensea'
import EtherScan from '../../../common/links/etherscan/Etherscan'
import { Transfer } from '../../transfer/Transfer'
import useStyles from '../TableBody.styles'
import { ITokenMeta } from '../../Tokens.interfaces'
import { ITokenRow } from './TokenRow.interfaces'

export const TokenRow: React.FC<ITokenRow> = ({ token, chainId, contractId }) => {
  const classes = useStyles()
  const appContext = useContext(AppContext)
  const [tokenMeta, setTokenMeta] = useState<ITokenMeta>({
    name: '',
    description: '',
    externalUrl: '',
    image: '',
  })
  const [open, setOpen] = useState(false)

  const hash = tokenMeta?.image.match(/[a-zA-z0-9]{46}/)?.[0]
  const nftImage = hash && `https://cloudflare-ipfs.com/ipfs/${hash}`

  useEffect(() => {
    if (token.metadata) setTokenMeta(JSON.parse(token.metadata as string))
  }, [token])

  const handleTransferToken = () => setOpen(true)

  const handleResyncMeta = async () => await resyncMetadata(appContext.chainId, contractId, token.token_id)

  return (
    <>
      <TableRow key={token.token_id}>
        <TableCell>{token.token_id}</TableCell>
        <TableCell>{tokenMeta && <img src={nftImage} alt="token-image" className={classes.image} />}</TableCell>
        <TableCell>{tokenMeta?.name}</TableCell>
        <TableCell>{tokenMeta?.description}</TableCell>
        <TableCell>
          {availableChainIds.includes(chainId) && (
            <>
              <OpenSea link={`https://${getOpenSeaLink(chainId)}/${contractId}/${token.token_id}`} />
              <EtherScan link={`https://${getScanLink(chainId)}/token/${contractId}?a=${token.token_id}`} />
            </>
          )}
        </TableCell>
        <TableCell>
          <Button className={classes.button} color="primary" size="small" onClick={handleTransferToken}>
            transfer token
          </Button>
        </TableCell>
        <TableCell>
          <Button className={classes.button} color="primary" size="small" onClick={handleResyncMeta}>
            resync meta
          </Button>
        </TableCell>
      </TableRow>
      <Transfer tokenId={token.token_id} contractAddress={contractId} open={open} setOpen={setOpen} />
    </>
  )
}
