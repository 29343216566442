import React, { FC } from 'react'
import openSea from './opensea-logo.svg'
import useStyles from './OpenSea.styles'

interface IOpenSea {
  link: string
}

const OpenSea: FC<IOpenSea> = ({ link }) => {
  const classes = useStyles()
  const handleRedirect = () => window.open(link, '_blank')

  return <img src={openSea} alt="OpenSea-logo" className={classes.icon} onClick={handleRedirect} />
}

export default OpenSea
