import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  logout: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: '10px',
    padding: '20px',
  },
  icon: {
    '&.MuiSvgIcon-root': {
      fontSize: '30px',
      color: primary.main,
      cursor: 'pointer',
    },
  },
}))

export default useStyles
