import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'

// local
import useStyles from './Mint.styles'
import WizardContext from 'contexts/wizard/WizardContext'
import { INFO_MESSAGE } from './Mint.alerts'
import useSnackBar from 'hooks/useSnackbar/useSnackbar'

// components
import StepperButtons from '../stepperButtons/StepperButtons'
import Name from './name/Name'
import ExternalUrl from './externalUrl/ExternalUrl'
import ContractId from './contractId/ContractId'
import DescriptionInput from './description/Description'

const MintToken: React.FunctionComponent = () => {
  const classes = useStyles()
  const showMessage = useSnackBar()
  const wizard = useContext(WizardContext)
  const { id } = useParams()
  const externalUrl = 'https://www.credenza3.com'
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const handleNextStep = () => {
    if (!name || !description) return showMessage(INFO_MESSAGE, 'info')
    const data = {
      name: name,
      description: description,
      externalUrl: externalUrl,
    }

    wizard.handleSetTokenForm?.(data)
    wizard.handleNext()
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Minting</Typography>
      <div className={classes.form}>
        <div className={classes.row}>
          <Name value={name} setState={setName} />
          <ExternalUrl value={externalUrl} />
        </div>
        <ContractId value={id as string} />
        <DescriptionInput value={description} setState={setDescription} />
      </div>
      <StepperButtons
        label="Next"
        handleBack={wizard.handleBack}
        activeStep={wizard.activeStep}
        handleNext={handleNextStep}
      />
    </div>
  )
}

export default MintToken
