import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import useStyles from './ContractHead.styles'
import { IHead } from './ContractHead.interfaces'
import { getNetwork } from '../../../helpers/strings/strings'

const ContractHead: React.FC<IHead> = ({ contractId, contract }) => {
  const classes = useStyles()

  return (
    <div className={classes.titleBox}>
      <div>
        <Typography>Address {contractId}</Typography>
        <div className={classes.row}>
          <Typography>Network: </Typography>
          <Typography className={classes.network}>{getNetwork(contract.chainId)}</Typography>
        </div>
        <div className={classes.row}>
          <Typography>ChainId: </Typography>
          <Typography className={classes.chainId}>{contract.chainId}</Typography>
        </div>
      </div>
      <Button
        component={Link}
        to={`/mint-token/contract/${contractId}`}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Mint token
      </Button>
    </div>
  )
}

export default ContractHead
