import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

// components
import {
  ROUTE_DASHBOARD,
  ROUTE_DEPLOY_CONTRACT,
  ROUTE_MINT_TOKEN,
  ROUTE_DISCORD_AUTH,
  ROUTE_USER_PROFILE,
  ROUTE_CONTRACT,
  ROUTE_SIGNIN,
} from './RouteMap'
import Dashboard from '../pages/dashboard/Dashboard'
import MintToken from './../pages/mintToken/MintToken'
import DiscordAuth from '../pages/discordAuth/DiscordAuth'
import UserProfile from '../pages/userProfile/UserProfile'
import Contract from '../pages/contract/Contract'
import Auth from '../pages/auth/Auth'
import CreateContract from '../pages/createContract/CreateContract'
import { NotFound } from '../pages/notFound/NotFound'

const AppRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path={ROUTE_SIGNIN} element={<Auth />} />
      <Route path={ROUTE_DISCORD_AUTH} element={<DiscordAuth />} />
      <Route path={ROUTE_DASHBOARD} element={<Dashboard />} />
      <Route path={ROUTE_USER_PROFILE} element={<UserProfile />} />
      <Route path={ROUTE_CONTRACT} element={<Contract />} />
      <Route path={ROUTE_DEPLOY_CONTRACT} element={<CreateContract />} />
      <Route path={ROUTE_MINT_TOKEN} element={<MintToken />} />
      <Route path="/" element={<Navigate to={ROUTE_DASHBOARD} replace={true} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
