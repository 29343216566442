import React, { useState } from 'react'
import Contracts from 'components/tables/contracts/Contracts'
import Transactions from 'components/tables/transactions/Transactions'
import useStyles from './Dashboard.styles'
import Title from '../common/title/Title'
import BackdropLoader from 'components/backdrop/BackdropLoader'

const Dashboard: React.FunctionComponent = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  return (
    <div className={classes.root}>
      <Title title="Dashboard" />
      <Contracts setLoading={setLoading} />
      <Transactions setLoading={setLoading} />
      <BackdropLoader open={loading} />
    </div>
  )
}

export default Dashboard
