import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '20px 0',
  },
  container: {
    paddingTop: '10px',
  },
  chip: {
    margin: '0 10px',
  },
}))

export default useStyles
