import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  title: {
    '&.MuiTypography-root': {
      fontWeight: 700,
      padding: '10px 20px',
      background: primary.light,
      color: primary.main,
      fontSize: '16px',
      textAlign: 'left',
    },
  },
}))

export default useStyles
