import React, { useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { useMoralis, useMoralisFile } from 'react-moralis'

//local
import StepperButtons from '../stepperButtons/StepperButtons'
import WizardContext from 'contexts/wizard/WizardContext'
import useSnackBar from 'hooks/useSnackbar/useSnackbar'
import { mintToken } from './UploadImage.services'
import { CHAIN_ID_INFO_MESSAGE, INFO_MESSAGE } from './UploadImage.alerts'
import Dropzone from '../../dropzone/Dropzone'
import { params } from './UploadImage.config'
import AppContext from 'contexts/appContext/AppContext'
import { Loader } from '../../deployContract/loader/Loader'
import { IMetaData } from './UploadImage.interfaces'

const UploadImage: React.FunctionComponent = () => {
  const wizard = useContext(WizardContext)
  const { chainId } = useContext(AppContext)
  const { id } = useParams()
  const showMessage = useSnackBar()

  const { saveFile } = useMoralisFile()
  const { user } = useMoralis()
  const publicKey = user?.get('ethAddress')

  const { acceptedFiles, getInputProps, getRootProps, open, isDragAccept, isDragReject } = useDropzone(params)
  const file = acceptedFiles.map((file) => file)[0]
  const [loading, setLoading] = useState(false)

  const handleUploadFile = async () => {
    if (!file) return showMessage(INFO_MESSAGE, 'info')
    if (!chainId) return showMessage(CHAIN_ID_INFO_MESSAGE, 'info')

    await mintToken(
      file,
      saveFile,
      chainId,
      wizard.tokenForm as IMetaData,
      id as string,
      showMessage,
      setLoading,
      publicKey,
    )
  }

  return (
    <>
      <Dropzone
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        title="Upload image"
        text="Drag and drop an image here"
        file={file}
        buttonTitle="Browse an image"
        open={open}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
      />
      <StepperButtons
        label="Mint token"
        activeStep={wizard.activeStep}
        handleBack={wizard.handleBack}
        handleNext={handleUploadFile}
      />
      {loading && <Loader subTitle="Minting token" />}
    </>
  )
}

export default UploadImage
