/* eslint-disable @typescript-eslint/no-explicit-any */
import Moralis from 'moralis'
import { IDeploy, ISendToDB } from './DeployContract.interfaces'
import { CONTRACT_DEPLOYED, CONTRACT_TRANSACTION_SEND } from './DeployContract.alerts'
import { API } from 'services/api'

const ethers = Moralis.web3Library
const CONTRACTS_ARGUMETNTS: any = {
  LoyaltyContract: [ethers.constants.AddressZero],
  DeComContract: ['Credenza Decom Token', 'CDT'],
  AdDeComContract: ['Credenza AdDecom Token', 'CADT'],
  CombinedContract: ['Credenza Combined Token', 'CCT'],
  CredenzaNftFactory: ['Credenza Nft Factory Token', 'CNFT'],
  CredenzaNftFactoryGenerator: ['Credenza Nft Factory Generator Token', 'CNFGT'],
  CredenzaERC721Contract: ['Credenza ERC721 Token', 'CET'],
}

export const deploy: IDeploy = async (contract, setMessage, showMessage, chainId, setLoading, type) => {
  const currentProvider = new ethers.providers.Web3Provider((await Moralis.provider) as any)

  try {
    setMessage('Deploying contract')
    setLoading(true)
    const { bytecode, abi, contractName } = contract
    const args = CONTRACTS_ARGUMETNTS[contractName] ? CONTRACTS_ARGUMETNTS[contractName] : []
    const factory = new ethers.ContractFactory(abi, bytecode, currentProvider.getSigner())
    const contractFactory = await factory.deploy(...args)
    showMessage(CONTRACT_TRANSACTION_SEND, 'success')
    setMessage('Sending contract to DB')

    await sendContractToDB(
      contractName,
      contractFactory.deployTransaction.hash,
      contractFactory.address,
      chainId,
      showMessage,
      type,
    )

    await contractFactory.deployed()

    showMessage(CONTRACT_DEPLOYED, 'success')
    setLoading(false)
  } catch (e) {
    setLoading(false)
    showMessage(e.response?.data?.message || e.message, 'error')
  }
}

const sendContractToDB: ISendToDB = async (contractName, trHash, address, chainId, showMessage, type) => {
  const data = {
    address,
    transactionHash: trHash,
    type,
    file: {
      name: contractName,
      chainId,
    },
  }
  try {
    await API.post(`/chains/${chainId}/contracts`, data)
    showMessage('Contract has been sent to DB', 'success')
  } catch (e) {
    showMessage(e.response.data.message, 'error')
  }
}
