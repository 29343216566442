import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: '15px 0',
  },
  input: {
    padding: '0 0 0 15px',
    width: '100%',
    fontSize: '18px',
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > p': {
      marginLeft: 0,
    },
  },
}))
