import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useStyles } from './Loader.styles'
import { ILoader } from './Loader.interfaces'

export const Loader: React.FC<ILoader> = ({ subTitle }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" className={classes.loader} size={64} />
      <Typography className={classes.title}>Please don&apos;t close the tab</Typography>
      <Typography className={classes.subtitle}>{subTitle}</Typography>
    </div>
  )
}
