import React, { FC } from 'react'
import { TextField } from '@mui/material'

//local
import useStyles from './Description.styles'
import { IDescriptionInput } from './Description.interfaces'

const DescriptionInput: FC<IDescriptionInput> = ({ value, setState }: IDescriptionInput) => {
  const classes = useStyles()

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value)
  }
  return (
    <TextField
      multiline
      value={value}
      className={classes.field}
      color="primary"
      variant="outlined"
      onChange={handleFieldChange}
      InputProps={{ className: classes.input }}
      placeholder="Add description"
    />
  )
}

export default DescriptionInput
