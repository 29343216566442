import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  root: {
    '&.MuiFormControl-root': {
      width: '100%',
    },
  },
  select: {
    '& > div > svg': {
      display: 'none',
    },
    '& > div > div': {
      background: 'none',
      margin: 0,
    },
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}))
