import { makeStyles } from '@mui/styles'
import theme from '../../../theme'

const { success } = theme.palette

const useStyles = makeStyles(() => ({
  content: {
    height: '325px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    '&.MuiTypography-root': {
      fontSize: '25px',
      fontWeight: 'bold',
      marginTop: '25px',
    },
  },
  checkIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: '55px',
      fill: success.main,
    },
  },
  button: {
    '&.MuiButton-root': {
      margin: '15px 5px 0 0',
      width: '168px',
      height: '56px',
      boxShadow: 'none',
      textTransform: 'none',
      marginTop: '30px',
    },
  },
}))

export default useStyles
