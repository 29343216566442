import React, { FC } from 'react'
import { TextField } from '@mui/material'

//local
import useStyles from './Name.styles'
import { INameInput } from './Name.interfaces'

const Name: FC<INameInput> = ({ value, setState }: INameInput) => {
  const classes = useStyles()

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value)
  }
  return (
    <TextField
      value={value}
      className={classes.field}
      color="primary"
      variant="outlined"
      onChange={handleFieldChange}
      InputProps={{ classes: { input: classes.input } }}
      placeholder="Enter name"
    />
  )
}

export default Name
