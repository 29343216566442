import React, { FC } from 'react'
import { Typography } from '@mui/material'
import useStyles from './Title.styles'
import { ITitle } from './Title.interfaces'

const Title: FC<ITitle> = ({ title }: ITitle) => {
  const classes = useStyles()

  return <Typography className={classes.title}>{title}</Typography>
}

export default Title
