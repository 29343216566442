import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  contrainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  generateButton: {
    maxWidth: '200px',
  },
  keysContrainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  key: {
    wordBreak: 'break-all',
    background: '#dc143c',
    padding: '2px',
    borderRadius: '5px',
  },
}))

export default useStyles
