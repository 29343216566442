import { API } from '../../services/api'
import { IDiscordAuth } from './DiscordAuth.interfaces'
import { SUCCESS_MESSAGE } from './DiscordAuth.alerts'
import { ROUTE_USER_PROFILE } from '../../routes/RouteMap'

export const connectDiscord: IDiscordAuth = async (
  code,
  redirectUri,
  setLoading,
  showMessage,
  navigate,
): Promise<void> => {
  try {
    await API.post('/users/discord', { code, redirectUri })
    showMessage(SUCCESS_MESSAGE, 'success')
    navigate(ROUTE_USER_PROFILE)
  } catch (error) {
    showMessage(error.response.data.message ?? 'Error', 'error')
  } finally {
    setLoading(false)
  }
}
