import React, { useContext, useState } from 'react'

// material
import Button from '@mui/material/Button'

// local
import { INFO_MESSAGE } from './DeployContract.alerts'
import useSnackBar from 'hooks/useSnackbar/useSnackbar'
import { useStyles } from './DeployContract.styles'
import { deploy } from './DeployContract.services'
import { Loader } from './loader/Loader'
import AppContext from 'contexts/appContext/AppContext'
import { ContractTypeSelect } from '../contractTypeSelect/ContractTypeSelect'
import { ContractSelect } from '../contractSelect/ContractSelect'
import { IContract } from './DeployContract.interfaces'

const DeployContract: React.FunctionComponent = () => {
  const classes = useStyles()
  const showMessage = useSnackBar()
  const appContext = useContext(AppContext)

  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState<string>('')
  const [contract, setContract] = useState<IContract | null>(null)

  const handleDeploy = async () => {
    if (!contract) return showMessage(INFO_MESSAGE, 'info')
    await deploy(contract, setMessage, showMessage, appContext.chainId, setLoading, type)
  }
  return (
    <>
      <ContractSelect setValue={setContract} />
      <div className={classes.column}>
        <ContractTypeSelect value={type} setValue={setType} />
        <Button className={classes.button} onClick={handleDeploy} variant="contained" color="primary">
          Deploy
        </Button>
      </div>
      {loading && <Loader subTitle={message} />}
    </>
  )
}

export default DeployContract
