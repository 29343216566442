import { createContext } from 'react'
import { IWizardContext } from './WizardContext.interfaces'

const wizardContextDefaultValue: IWizardContext = {
  handleBack: (): void => {},
  handleNext: (): void => {},
  handleSetTokenForm: (): void => {},
  activeStep: 0,
}

const WizardContext = createContext<IWizardContext>(wizardContextDefaultValue)

export default WizardContext
