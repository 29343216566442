import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import useStyles from './NoDataMessage.styles'

const NoDataMessage: React.FunctionComponent = () => {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell colSpan={6} className={classes.cell}>
        No data
      </TableCell>
    </TableRow>
  )
}

export default NoDataMessage
