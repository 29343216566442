import React, { FC, useMemo } from 'react'

//material
import { Typography } from '@mui/material'
import Divider from '../divider/Divider'
import Button from '@mui/material/Button'

//local
import { useStyles, acceptStyle, rejectStyle } from './Dropzone.styles'
import { IDropzone } from './Dropzone.interfaces'

const Dropzone: FC<IDropzone> = ({
  getInputProps,
  getRootProps,
  title,
  text,
  buttonTitle,
  file,
  open,
  isDragAccept,
  isDragReject,
}: IDropzone) => {
  const classes = useStyles()

  const style = useMemo(
    () => ({
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragReject, isDragAccept],
  )
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.container} {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography className={classes.text}>{text}</Typography>
        <Divider />
        <Button type="button" onClick={open} className={classes.button} variant="outlined" color="primary">
          {buttonTitle}
        </Button>
        {file && (
          <div className={classes.file}>
            <Typography>{file.name}</Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default Dropzone
