import { config } from '../env-config'

const { apiUrl } = config

const getAuthHeader = () => {
  const token = localStorage.getItem('accessToken') || ''
  return {
    Authorization: token,
    'Content-Type': 'application/json',
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRequest = async (path: string): Promise<any> => {
  const result = await fetch(apiUrl + path, { headers: getAuthHeader() })
  return result
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postRequest = async (path: string, body: FormData | any): Promise<any> => {
  const result = await fetch(apiUrl + path, {
    method: 'POST',
    body: body instanceof FormData ? body : JSON.stringify(body),
    headers: getAuthHeader(),
  })
  return result
}

export const API = {
  get: getRequest,
  post: postRequest,
}
