import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  field: {
    '&.MuiFormControl-root': {
      borderColor: primary.light,
      width: '100%',
      marginTop: '10px',
    },
  },
  input: {
    '& > textarea': {
      height: '50px !important',
    },
  },
}))

export default useStyles
