import { API } from '../../services/api'
import { IContract, IChannels, IContractTokens } from './Contract.interfaces'
import { Dispatch, SetStateAction } from 'react'
import { getNetwork } from '../../helpers/strings/strings'
import { components } from 'moralis/types/generated/web3Api'
import Moralis from 'moralis'

export const getContract = async (
  contractId: string,
  chainId: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
): Promise<IContract> => {
  try {
    const response = await API.get(`/chains/${chainId}/contracts/${contractId}`)
    return await response.json()
  } finally {
    setLoading(false)
  }
}

export const getContractChannels = async (
  contractId: string,
  chainId: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
): Promise<IChannels[]> => {
  try {
    const response = await API.get(`/chains/${chainId}/contracts/${contractId}/discord/channels`)
    return await response.json()
  } finally {
    setLoading(false)
  }
}

export const getContractTokens = async (
  publicKey: string,
  chainId: string,
  contractAddress: string,
): Promise<IContractTokens> => {
  try {
    const options = {
      chain: getNetwork(chainId).toLowerCase() as components['schemas']['chainList'],
      address: publicKey,
      token_address: contractAddress,
    }
    return await Moralis.Web3API.account.getNFTsForContract(options)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return { result: [] }
  }
}
