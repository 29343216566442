import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      light: '#F6F6F6',
      main: '#37474f',
      dark: '#263238',
      contrastText: '#fff',
    },
    secondary: {
      light: '#E9EFFF',
      main: '#0277bd',
      contrastText: '#D4D4D4',
    },
    error: {
      main: '#FB3640',
    },
    success: {
      dark: '#036B50',
      main: '#06D6A0',
      light: '#80D6C0',
    },
    info: {
      main: '#38ACE0',
    },
    warning: {
      light: '#FFDA85',
      main: '#efbc4c',
      dark: '#AD852A',
    },
  },
})

export default theme
