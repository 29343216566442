import { makeStyles } from '@mui/styles'
import theme from './theme'

const { success, error, info } = theme.palette

const useStyles = makeStyles(() => ({
  contentRoot: {
    boxShadow: 'none',
    fontSize: '15px',
  },
  app: {
    display: 'flex',
  },
  pages: {
    padding: '24px',
    width: '100%',
  },
  variantSuccess: {
    backgroundColor: success.main,
  },
  variantError: {
    backgroundColor: error.main,
  },
  variantInfo: {
    backgroundColor: info.main,
  },
}))

export default useStyles
