import { API } from '../../../services/api'
import { SUCCESS_MESSAGE } from './ConnectChannel.alerts'
import { IChannels, IGuilds, ISendChannel } from './ConnectChannel.interfaces'

export const getDiscordGuilds = async (): Promise<IGuilds[]> => {
  const response = await API.get(`/discord/guilds`)
  return await response.json()
}

export const getGuildChannel = async (guildId: string): Promise<IChannels[]> => {
  const response = await API.get(`/discord/guilds/${guildId}/channels`)
  return await response.json()
}

export const connectChannel: ISendChannel = async (
  formData,
  showMessage,
  guildId,
  setLoading,
  contractId,
  chainId,
): Promise<void> => {
  try {
    await API.post(`/chains/${chainId}/contracts/${contractId}/discord/guilds/${guildId}/channels`, formData)
    showMessage(SUCCESS_MESSAGE, 'success')
  } catch (error) {
    showMessage(error.response.data.message, 'error')
  } finally {
    setLoading(false)
  }
}
