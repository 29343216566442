import React, { useContext, useState } from 'react'

import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'

// local
import { useStyles } from './Transfer.styles'
import { ITransfer } from './Transfer.interfaces'
import RecipientPublicKey from './recipientPublicKey/RecipientPublicKey'
import { transfer } from 'components/transferToken/TransferTokenForm.services'
import useSnackBar from 'hooks/useSnackbar/useSnackbar'
import AppContext from 'contexts/appContext/AppContext'
import BackdropLoader from 'components/backdrop/BackdropLoader'

export const Transfer: React.FC<ITransfer> = ({ tokenId, contractAddress, open, setOpen }) => {
  const classes = useStyles()
  const showMessage = useSnackBar()
  const { chainId } = useContext(AppContext)
  const [recipientPublicKey, setRecipientPublicKey] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => setOpen(false)

  const transferToken = async () => {
    setLoading(true)
    await transfer(contractAddress, recipientPublicKey, chainId, tokenId, setLoading, showMessage)
    setLoading(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.root }}>
      <DialogTitle className={classes.title}>Transfer token</DialogTitle>
      <DialogContent>
        <RecipientPublicKey value={recipientPublicKey} setState={setRecipientPublicKey} />
        <Button className={classes.button} color="primary" variant="outlined" onClick={transferToken}>
          Transfer token
        </Button>
        <BackdropLoader open={loading} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="primary" className={classes.button}>
          Go back
        </Button>
      </DialogActions>
    </Dialog>
  )
}
