import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    zIndex: 100,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loader: {
    '&.MuiCircularProgress-root': {
      '& > svg > circle': {
        strokeWidth: '1.5',
      },
    },
  },
  title: {
    '&.MuiTypography-root': {
      fontSize: '18px',
      fontWeight: 700,
      padding: '40px 0 10px',
    },
  },
  subtitle: {
    '&.MuiTypography-root': {
      color: '#A0A9BE',
    },
  },
  emphasis: {
    color: '#1B39F5',
    cursor: 'pointer',
  },
}))
