import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles(({ palette: { primary } }: Theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    padding: '20px',
    margin: '20px',
    width: '100%',
    border: `1px solid ${primary.light}`,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    '&.MuiListItem-root': {
      paddingLeft: 0,
    },
  },
  itemTitle: {
    '&.MuiTypography-root': {
      paddingRight: '10px',
      fontWeight: 'bold',
    },
  },
  boxTitle: {
    '&.MuiTypography-root': {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
  chip: {
    margin: '0 10px 0 0',
  },
}))

export default useStyles
