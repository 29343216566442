import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  app: {
    display: 'flex',
  },
  pages: {
    padding: '24px',
    width: '100%',
  },
}))

export default useStyles
