import React from 'react'
import ReactDOM from 'react-dom/client'
import { MoralisProvider } from 'react-moralis'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { config } from './env-config'

const { appId, serverUrl } = config

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)
root.render(
  <React.StrictMode>
    <MoralisProvider serverUrl={serverUrl} appId={appId}>
      <App />
    </MoralisProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
