import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 20px',
  },
  root: {
    '& .MuiInput-root': {
      width: '100%',
      minWidth: '200px',
      marginTop: '6px',
    },
    '& .MuiInputBase-input': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '18px',
      padding: '2px 15px',
    },
    '& .MuiInputLabel-root': {
      top: '-5px',
    },
  },
  select: {
    '& > div > svg': {
      display: 'none',
    },
    '& > div > div': {
      background: 'none',
      margin: 0,
    },
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  icon: {
    '&.MuiSvgIcon-root': {
      fontSize: '15px',
      cursor: 'pointer',
      marginLeft: '10px',
    },
  },
}))
