import { config } from 'env-config'
import Moralis from 'moralis'

export const getLoginCode = async (apiUrl: string, userAddress: string) => {
  const result = await fetch(apiUrl + '/auth?address=' + userAddress)
  if (!result.ok) throw new Error(`${result.status} ${result.statusText}`)
  const json = await result.json()
  return json.loginCode
}

export const login = async (apiUrl: string, loginCode: string, signature: string, loginProvider: string) => {
  const result = await fetch(apiUrl + '/auth', {
    method: 'post',
    body: JSON.stringify({
      loginCode,
      signature,
      provider: loginProvider,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (!result.ok) throw new Error(`${result.status} ${result.statusText}`)
  return await result.json()
}

export const initEthersProvider = async (chainId: string) => {
  const ethers = Moralis.web3Library
  if (!window.ethereum) return null

  await window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: '0x' + Number(chainId).toString(16) }],
  })
  await window.ethereum.request({ method: 'eth_requestAccounts' })
  return new ethers.providers.Web3Provider(window.ethereum)
}

export const getAccessToken = async (providerType: string) => {
  try {
    const ethersProvider = await initEthersProvider(window.ethereum?.networkVersion)
    if (!ethersProvider) return ''

    const signer = ethersProvider.getSigner()
    const loginCode = await getLoginCode(config.apiUrl, window.ethereum?.selectedAddress)
    const signature = await signer.signMessage(loginCode)
    const { accessToken } = await login(config.apiUrl, loginCode, signature, providerType)
    localStorage.setItem('accessToken', accessToken)

    return accessToken
  } catch (e) {
    throw new Error('Unable to get accessToken: ', e)
  }
}
