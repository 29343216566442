import React, { useContext } from 'react'

//local
import MintToken from '../mint/Mint'
import UploadImage from '../uploadImage/UploadImage'
import WizardContext from '../../../contexts/wizard/WizardContext'

const StepContent: React.FunctionComponent = () => {
  const wizard = useContext(WizardContext)
  switch (wizard.activeStep) {
    case 0:
      return <MintToken />
    case 1:
      return <UploadImage />
    default:
      return <MintToken />
  }
}

export default StepContent
