import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'fixed',
    right: 20,
  },
  button: {
    '&.MuiButton-root': {
      textTransform: 'none',
      fontSize: '18px',
      margin: '0 20px',
    },
  },
}))
