const stage = process.env.REACT_APP_STAGE || 'staging'

const envConfig = {
  staging: {
    apiUrl: process.env.REACT_APP_API_ENDPOINT || 'https://api.testnets.credenza.online',
    wsEndpoint: process.env.REACT_APP_WS_ENDPOINT || 'wss://ws.testnets.credenza.online',
    serverUrl: process.env.REACT_APP_MORALIS_SERVER_URL || 'https://v0ypewrdu3an.usemoralis.com:2053/server',
    appId: process.env.REACT_APP_MORALIS_APP_ID || 'hZWy8T065pmGKMVvPMtPLz1gTcrWBt8rwgbmlMjF',
    apiKey: process.env.REACT_APP_MORALIS_APP_KEY || 'OH9sdRAHxgv5p02DrXhzCzfxSNnR1LvPzo177AH7hiJLvrenI6uTJotpwatBy6xO',
    chainIds: [
      { value: '5', label: 'Goerli' },
      { value: '80001', label: 'Polygon testnet' },
      { value: '97', label: 'Binance Smart Chain Testnet' },
    ],
    magic: {
      apiKey: 'pk_live_A2A484A5087E5029',
    },
  },
  prod: {
    apiUrl: process.env.REACT_APP_API_ENDPOINT || 'https://api.credenza.online',
    wsEndpoint: process.env.REACT_APP_WS_ENDPOINT || 'wss://ws.credenza.online',
    serverUrl: process.env.REACT_APP_MORALIS_SERVER_URL || 'https://7s3adrgppuht.usemoralis.com:2053/server',
    appId: process.env.REACT_APP_MORALIS_APP_ID || '2mEYqqlTGhtE8HmBRfip4LmwXepXDE4It3djpcIu',
    apiKey: process.env.REACT_APP_MORALIS_APP_KEY || 'OH9sdRAHxgv5p02DrXhzCzfxSNnR1LvPzo177AH7hiJLvrenI6uTJotpwatBy6xO',
    chainIds: [
      { value: '1', label: 'Mainnet' },
      { value: '137', label: 'Polygon' },
      { value: '56', label: 'Binance Smart Chain' },
    ],
    magic: {
      apiKey: 'pk_live_2827DCC277A9F490',
    },
  },
}

export const config = envConfig[stage as keyof typeof envConfig]
