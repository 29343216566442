import React, { useState } from 'react'

// material
import { Chip, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

// local
import useStyles from './DiscordAccounts.styles'
import { IDiscordAccounts } from './DiscordAccounts.interfaces'
import { URL_DISCORD } from '../../../routes/RouteMap'

const DiscordAccounts: React.FunctionComponent = () => {
  const classes = useStyles()
  const [discordAccounts] = useState<IDiscordAccounts[]>([])

  const handleAddDiscordAccount = () => window.open(URL_DISCORD)

  return (
    <>
      <Typography className={classes.title}>Attached discord accounts</Typography>
      <div className={classes.container}>
        <Button variant="contained" color="primary" onClick={handleAddDiscordAccount}>
          Add discord account <AddIcon />
        </Button>

        {discordAccounts.length > 0 &&
          discordAccounts.map(({ id, username }) => (
            <Chip key={id} label={username} className={classes.chip} variant="outlined" />
          ))}
      </div>
    </>
  )
}

export default DiscordAccounts
