import { createContext } from 'react'
import { IAppContext } from './AppContext.interfaces'

const appContextDefaultValue: IAppContext = {
  chainId: '',
  handleSetChainId: (): void => {},
  provider: { isStatus: false },
  handleSetProvider: (): void => {},
}

const AppContext = createContext<IAppContext>(appContextDefaultValue)

export default AppContext
