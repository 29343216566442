import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  buttons: {
    paddingTop: '30px',
    textTransform: 'none',
    '& > button': {
      margin: '15px 5px 0 0',
      width: '168px',
      height: '56px',
      boxShadow: 'none',
      textTransform: 'none',
    },
  },
}))

export default useStyles
