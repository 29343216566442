/* eslint-disable max-lines-per-function */
/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react'
import Moralis from 'moralis'

// material
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'

// local
import { useStyles } from './ChainIdSelector.styles'
import AppContext from 'contexts/appContext/AppContext'
import { config } from '../../env-config'
import { useChain, useMoralis } from 'react-moralis'
import { getLoginParams } from 'helpers/moralis/moralis'

const ChainIdSelector = () => {
  const classes = useStyles()
  const { switchNetwork } = useChain()
  const { enableWeb3, isWeb3Enabled, isWeb3EnableLoading } = useMoralis()
  const { chainId, handleSetChainId } = useContext(AppContext)
  const options: { value: string; label: string }[] = config.chainIds

  const enableProvider = async (chainIdSelected: string) => {
    const user = Moralis.User.current()
    if (user?.get('provider') === 'metamask') {
      if (!isWeb3Enabled && !isWeb3EnableLoading) await enableWeb3()
      try {
        await switchNetwork('0x' + Number(chainIdSelected).toString(16))
        // eslint-disable-next-line no-empty
      } catch (err) {}
    } else if (user?.get('provider') === 'magicLink') {
      const loginParams = getLoginParams('magicLink', chainIdSelected, user.get('email'))
      const provider = await Moralis.enableWeb3(loginParams)
      console.log(provider)
    }
  }
  const enableProviderCallback = React.useCallback(enableProvider, [
    enableWeb3,
    isWeb3Enabled,
    switchNetwork,
    isWeb3EnableLoading,
  ])

  useEffect(() => {
    const currentChainId = localStorage.getItem('chainId')
    const hasChainId = options.some((chains) => chains['value'] === currentChainId)
    const selectedChainId = hasChainId ? currentChainId : options[0].value
    if (!selectedChainId) return
    localStorage.setItem('chainId', selectedChainId)
    handleSetChainId(selectedChainId)

    void enableProviderCallback(selectedChainId)
  }, [handleSetChainId, options, enableProviderCallback])

  const handleSelectChange = async (event: SelectChangeEvent) => {
    const chainIdSelected = event.target.value as string
    if (!chainIdSelected) return
    await enableProvider(chainIdSelected)
    handleSetChainId(chainIdSelected)
    localStorage.setItem('chainId', chainIdSelected)
  }

  return (
    <FormControl className={classes.root}>
      <InputLabel>Select chain id</InputLabel>
      <Select
        value={chainId}
        onChange={handleSelectChange}
        classes={{ select: classes.select }}
        disableUnderline
        variant="standard"
        IconComponent={ExpandMoreIcon}
      >
        {options.map(({ label, value }) => (
          <MenuItem value={value} key={value} className={classes.menuItem}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ChainIdSelector
