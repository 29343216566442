import { scanLinkByChainId, openSeaLinkByChainId } from './strings.config'
import { config } from '../../env-config'
import { Dispatch, SetStateAction } from 'react'

export const trimDotJson = (value: string): string => value?.replace(/\.json$/, '')

export const validateEmail = (value: string): boolean => {
  const isEmailValid = value?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
  return !!isEmailValid
}

export const getFormattedDate = (date: string): string => {
  const makeDate = new Date(date)
  return makeDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
}

export const getNetwork = (chainId: string): string => {
  return config.chainIds.filter(({ value }) => value === chainId)[0]?.label
}

export const getScanLink = (chainId: string): string =>
  scanLinkByChainId.filter(({ value }) => value === chainId)[0]?.label

export const getOpenSeaLink = (chainId: string): string =>
  openSeaLinkByChainId.filter(({ value }) => value === chainId)[0]?.label

export const copyToClipboard = (value: string, setToolTipTitle: Dispatch<SetStateAction<string>>) => {
  void navigator.clipboard.writeText(value)
  setToolTipTitle('Copied')
}
