import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import theme from '../../theme'

const { success, error } = theme.palette

export const useStyles = makeStyles(({ palette: { secondary } }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    '&.MuiTypography-root': {
      padding: '36px 0',
      fontSize: '25px',
      fontWeight: 'bold',
    },
  },
  text: {
    color: secondary.contrastText,
  },
  container: {
    height: '196px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `3px solid ${secondary.contrastText}`,
    borderRadius: '20px',
    borderStyle: 'dashed',
    width: '350px',
  },
  button: {
    '&.MuiButton-root': {
      margin: '20px 0 0 0',
      width: '164px',
      height: '40px',
      boxShadow: 'none',
      textTransform: 'none',
    },
  },
  file: {
    marginTop: '20px',
    display: 'flex',
    '& > p': {
      marginLeft: '15px',
      fontSize: '18px',
    },
  },
}))

export const acceptStyle = {
  borderColor: success.main,
}

export const rejectStyle = {
  borderColor: error.main,
}
