import React, { FC, useContext } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import NoDataMessage from '../../common/noDataMessage/NoDataMessage'
import { getNetwork } from 'helpers/strings/strings'
import useStyles from './TableBody.styles'
import { ITableBodyContent } from './TableBody.interfaces'
import ButtonLink from './../buttonLink/ButtonLink'
import AppContext from 'contexts/appContext/AppContext'
import NoChainIdMessage from '../../common/noChainIdMessage/NoChainIdMessage'

const TableBodyContent: FC<ITableBodyContent> = ({ currentRows }) => {
  const classes = useStyles()
  const appContext = useContext(AppContext)

  if (!appContext.chainId) return <NoChainIdMessage />
  if (!currentRows.length) return <NoDataMessage />

  if (currentRows.length > 0) {
    return (
      <>
        {currentRows.map(({ address, file, chainId }) => (
          <TableRow key={address}>
            <TableCell>{address}</TableCell>
            <TableCell>{getNetwork(chainId)}</TableCell>
            <TableCell>{file?.name}</TableCell>
            <TableCell>
              <ButtonLink classes={classes} label="View more" to={`/contract/${address}`} />
            </TableCell>
          </TableRow>
        ))}
      </>
    )
  }
  return null
}

export default TableBodyContent
