import React, { FC } from 'react'
import useStyles from './Status.styles'
import { IStatus } from './Status.interfaces'

const Status: FC<IStatus> = ({ receiptStatus }: IStatus) => {
  const classes = useStyles()

  if (receiptStatus === '1') return <div className={classes.completed}>Completed</div>

  return <div className={classes.inProgress}>Pending</div>
}

export default Status
