import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import useStyles from './NoChainIdMessage.styles'

const NoChainIdMessage: React.FunctionComponent = () => {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell colSpan={6} className={classes.cell}>
        Please select chain id in the selector
      </TableCell>
    </TableRow>
  )
}

export default NoChainIdMessage
