import React, { FC } from 'react'
import { TextField } from '@mui/material'

//local
import useStyles from './CreateChannel.styles'
import { ICreateChannel } from './CreateChannel.interfaces'

const CreateChannel: FC<ICreateChannel> = ({ value, setState, selectedGuild }) => {
  const classes = useStyles()

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value)
  }
  return (
    <TextField
      value={value}
      className={classes.field}
      color="primary"
      variant="outlined"
      onChange={handleFieldChange}
      InputProps={{ classes: { input: classes.input } }}
      placeholder="Create new channel | enter name"
      disabled={!selectedGuild}
    />
  )
}

export default CreateChannel
